.button-icon {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
}

.button-empty {
  border: none;
  background: none;
  padding: 0.2rem 1.2rem;
}

.blured-menu {
  transition: all 0.6s;
  &.day {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .4);	
  }
  &.night {
    box-shadow: 0 0 1rem 0 rgba(255, 255, 255, .35);	
  }
	z-index: 1;
	background: inherit;
	overflow: hidden;
  &.day {
    &::before {
      box-shadow: inset 0 0 2000px rgba(0, 0, 0, .47);
    }
  }
  &.night {
    &::before {
      box-shadow: inset 0 0 2000px rgba(0, 0, 0, .1);
    }
  }
  &::before {
    transition: all 0.8s;
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(10px);
    backdrop-filter: blur(4px) brightness(1.1);
    margin: -20px;
  }
}

.bottom-right {
  top: 88vh;
  top: calc((var(--vh, 1vh) * 92) - 83px);
}

.top-right {
  top: 8vw;
  top: calc(var(--vh, 1vh) * 8);
}

.bottom-left {
  left: 4vh;
  left: calc(var(--vw, 1vh) * 4);
  top: 94vh;
  top: calc(var(--vh, 1vh) * 92);
}

.top-left {
  left: 3vh;
  left: calc(var(--vw, 1vh) * 3);
  top: 2vw;
  top: calc(var(--vh, 1vh) * 2);
}

.middle-right {
  top: 50vh;
  top: calc(var(--vh, 1vh) * 50);
}

.middle-left {
  left: 2vh;
  left: calc(var(--vh, 1vh) * 2);
  top: 50vw;
  top: calc(var(--vh, 1vh) * 50);
}

.infinity-top {
  top: -100vh;
  top: calc(var(--vh, 1vh) * 100 * -1);
}

.infinity-right {
  right: -100vh;
  right: calc(var(--vh, 1vh) * 100 * -1);
}

.skills-opened, .skills-opened * {
  z-index: 100;
  opacity: 1;
}

.skills-closed, .skills-closed * {
  z-index: -99999;
  opacity: 0;
}

.skills-opening, .skills-opening * {
  opacity: 1;
}

.skills-closing, .skills-closing * {
  opacity: 0;
}

.icon {
  svg {
    color: white;
    font-size: 24px
  }
}

/*
* Popover Styles
* "up" | "left" | "right" | "down" | "up-left" | "up-right" | "down-left" | "down-right"
* aria-label={message}
* data-balloon-pos={position}
* -------------
*/

:root {
  --balloon-border-radius: 6px;
  --balloon-color: black;
  --balloon-border: none;
  --balloon-text-color: white;
  --balloon-font-size: 14px;
  --balloon-move: 6px;
}

button[aria-label][data-balloon-pos] {
  overflow: visible;
}

[aria-label][data-balloon-pos] {
  position: relative;
  left: 0;
  max-width: 98vw;
}

[aria-label][data-balloon-pos]:after {
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  text-indent: 0;
  font-weight: normal;
  font-style: normal;
  text-shadow: none;
  font-size: inherit;
  background: inherit;
  border: var(--balloon-border);
  border-radius: 2px;
  color: var(--balloon-text-color);
  border-radius: var(--balloon-border-radius);
  content: attr(aria-label);
  padding: .5em 1em;
  position: absolute;
  word-break: break-all;
  white-space: nowrap;
  z-index: 10;
}

[aria-label][data-balloon-pos]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: inherit;
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  content: "";
  position: absolute;
  z-index: 10;
}

[aria-label][data-balloon-pos]:hover:before, [aria-label][data-balloon-pos]:hover:after, [aria-label][data-balloon-pos][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-visible]:after, [aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:before, [aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:after {
  opacity: 1;
  pointer-events: none;
}

[aria-label][data-balloon-pos].font-awesome:after {
  font-family: FontAwesome, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

[aria-label][data-balloon-pos][data-balloon-break]:after {
  white-space: pre;
}

[aria-label][data-balloon-pos][data-balloon-break][data-balloon-length]:after {
  white-space: pre-line;
  word-break: break-word;
}

[aria-label][data-balloon-pos][data-balloon-blunt]:before, [aria-label][data-balloon-pos][data-balloon-blunt]:after {
  transition: none;
}

[aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:after {
  transform: translate(-50%, 0);
}

[aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:before {
  transform: translate(-50%, 0);
}

[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:after {
  left: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:before {
  left: 5px;
}

[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:after {
  right: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:before {
  right: 5px;
}

[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos*="-left"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:after {
  transform: translate(0, 0);
}

[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos*="-left"][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:before {
  transform: translate(0, 0);
}

[aria-label][data-balloon-pos][data-balloon-pos^="up"]:before, [aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  bottom: 100%;
  transform-origin: top;
  transform: translate(0, var(--balloon-move));
}

[aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  margin-bottom: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos="up"]:before, [aria-label][data-balloon-pos][data-balloon-pos="up"]:after {
  left: 50%;
  transform: translate(-50%, var(--balloon-move));
}

[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before, [aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  top: 100%;
  transform: translate(0, calc(var(--balloon-move) * -1));
}

[aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  margin-top: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: inherit;
}

[aria-label][data-balloon-pos][data-balloon-pos="down"]:after, [aria-label][data-balloon-pos][data-balloon-pos="down"]:before {
  left: 50%;
  transform: translate(-50%, calc(var(--balloon-move) * -1));
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:after {
  transform: translate(0, -50%);
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:before {
  transform: translate(0, -50%);
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:after, [aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  right: 100%;
  top: 50%;
  transform: translate(var(--balloon-move), -50%);
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:after {
  margin-right: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: inherit;
}

[aria-label][data-balloon-pos][data-balloon-pos="right"]:after, [aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  left: 100%;
  top: 50%;
  transform: translate(calc(var(--balloon-move) * -1), -50%);
}

[aria-label][data-balloon-pos][data-balloon-pos="right"]:after {
  margin-left: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: inherit;
}

[aria-label][data-balloon-pos][data-balloon-length]:after {
  white-space: normal;
}

[aria-label][data-balloon-pos][data-balloon-length="small"]:after {
  width: 80px;
}

[aria-label][data-balloon-pos][data-balloon-length="medium"]:after {
  width: 150px;
}

[aria-label][data-balloon-pos][data-balloon-length="large"]:after {
  width: 260px;
}

[aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
  width: 380px;
}

@media screen and (max-width: 768px) {
  [aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
      width: 90vw;
 }
}

[aria-label][data-balloon-pos][data-balloon-length="fit"]:after {
  width: 100%;
}

/*
* -------------
* -------------
*/

.range-slider {
  width: 480px;
  margin: 60px auto 0;
}

.range-slider.focus-slider.vertical-slider {
  background-color: white;
}

.focus-slider {

  .range-slider__range {
    border-radius: 6px;
  }

  .range-slider__thumb[data-lower] {
    width: 0;
  }
}

.vertical-slider[data-vertical] {
  height: 180px;
}

.range-slider .range-slider__thumb {
  background: white;
}
