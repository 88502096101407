.fullscreen-content {
  position: absolute;
  z-index: 9999999;
  height: 100vh;
  width: 100vw;
  background-color: #7f7f7f;
  background-image:
    radial-gradient(
      #8b8b8b,
      #5d5d5d
    );
  display: flex;
  align-items: center;
  .fullscreen-blink-bg {
    z-index: 100;
    position: absolute;
    top: 30%;
    left: 50%;
    width: 40%;
    height: 40%;
    transform: translateX(-50%);
    border-radius: 50%;
    background: #afafaf;
    -moz-filter: blur(60px);
    -o-filter: blur(60px);
    -ms-filter: blur(60px);
    filter: blur(100px);
    animation: blinkIt .1s infinite;
  }
  .fullscreen-images-wrapper {
    z-index: 200;
    width: 100%;
    text-align: center;
    text-align: -webkit-center;
    .fullscreen-image {
      margin: auto;
      &:first-child {
        max-width: 60%;
      }
      // &:last-child {
      //   max-width: 40%;
      // }
    }
  }
  .fullscreen-text {
    z-index: 300;
    position: absolute;
    top: 20%;
    width: 100%;
    text-align: center;
  }
  .fullscreen-button {
    z-index: 300;
    position: absolute;
    top: 80%;
    width: 100%;
    text-align: center;
  }
}

@keyframes blinkIt {
  50% { opacity: .8;}
}
