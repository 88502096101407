.cloud {
  opacity: 1;
}

.container {
  z-index: 1;
  display: flex;
}

.bird {
  &--day {
    background-image: url(https://imagedelivery.net/9bD4jGTLu2p50iCVRkMO1A/1390f760-f04d-4912-ae7a-fa6d1c494200/clock);
  }

  &--night {
    background-image: url(https://imagedelivery.net/9bD4jGTLu2p50iCVRkMO1A/c3da3003-fb94-4a07-85e8-c18aa2775400/clock);
  }

  background-size: auto 100%;
  width: 5.46vw;
  height: 7.65vw;
  will-change: background-position;

  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;


  &--one {
    animation-duration: 3s;
    animation-delay: 0s;
  }
}

.bird-container {
	transform: scale(0) translateX(-10vw);
  will-change: transform;
  animation-name: fly-right;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &--one {
  	animation-duration: 130s;
  	animation-delay: 0;
  }
}

@keyframes fly-cycle {

  100% {
    background-position: -110vw 0;
  }

}

@keyframes fly-right {

	0% {
		transform: scale(0.2) translateX(-10vw);
	}

	10% {
		transform: translateY(2vh) translateX(10vw) scale(0.21);
	}

	20% {
		transform: translateY(0vh) translateX(30vw) scale(0.22);
	}

	30% {
		transform: translateY(4vh) translateX(50vw) scale(0.23);
	}

	40% {
		transform: translateY(2vh) translateX(70vw) scale(0.22);
	}

	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.21);
	}

	60% {
		transform: translateY(0vh) translateX(110vw) scale(0.21);
	}

	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.2);
	}

}

$s: 60;
$shadow: ();

@for $i from 1 through $s {
  $x: random(200);
  $y: random(500);
  $shadow: $shadow, ( $x*1vh $y*1vw #fff) !global;
}

.star {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadow;
}

$ss: 30;
$shadows: ();

@for $i from 1 through $s {
  $xs: random(200);
  $ys: random(500);
  $shadows: $shadows, ( $xs*1vh $ys*1vw #fff) !global;
}

.star-s {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows;
}
