// .skills-wrapper  {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   .skills-content {
//     max-width: calc(94vw - 80px);
//     max-width: calc((var(--vw, 1vw) * 94) - 80px);
//     height: 90vh;
//     height: calc(var(--vh, 1vh) * 90);
//     -webkit-overflow-scrolling: touch !important;
//   }
// }
.skills-content {
  max-width: calc(94vw - 80px);
  max-width: calc((var(--vw, 1vw) * 94) - 80px);
  height: 90vh;
  height: calc(var(--vh, 1vh) * 90);
  -webkit-overflow-scrolling: touch !important;
}
